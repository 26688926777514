<template>
	<div class="container">
		<top-header></top-header>
		<div class="updatepassword width-1300">
			<div class="title">
				<span>修改密码</span>
			</div>
			<div class="list">
				<input type="text" placeholder="请输入手机号" v-model="fromData.phone">
			</div>
			<div class="list">
				<input type="text" placeholder="请输入验证码" class="code-input" v-model="fromData.code">
				<div class="btn round" @click="sendSms()" v-if="!isDisabled">{{content}}</div>
				<!-- // 发送验证码按钮--不可点击的 -->
				<div class="btn round wait" v-if="isDisabled">{{content}}</div>
			</div>
			<div class="list">
				<input type="password" placeholder="请输入新密码" v-model="fromData.password">
			</div>
			<div class="list">
				<input type="password" placeholder="确认新密码" v-model="fromData.andpass">
			</div>
			<div class="bottom-btn">
				<span>确定修改</span>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../../components/header.vue'
	import bottomFooter from '../../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				fromData:{
					phone:'',
					code:'',
					password:'',
					andpass:''
				},
				isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
				content: '发送验证码', // 发送验证码按钮的初始显示文字
				timer: null,
				count: '',
				model: {},
			}
		},
		created() {

		},
		methods: {
			sendSms() {
				let vm = this
				// 校验手机号
				// if (!vm.model.username || vm.model.username == '') {
				// 	vm.$layer.msg('请输入手机号')
				// 	return
				// }
				// if (!(/^1[34578]\d{9}$/.test(vm.model.username))) {
				// 	vm.$layer.msg('请输入正确的手机号')
				// 	return
				// }
				// 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
				// 控制倒计时及按钮是否可以点击
				const TIME_COUNT = 60
				vm.count = TIME_COUNT
				vm.timer = window.setInterval(() => {
					if (vm.count > 0 && vm.count <= TIME_COUNT) {
						// 倒计时时不可点击
						vm.isDisabled = true
						// 计时秒数
						vm.count--
						// 更新按钮的文字内容
						vm.content = vm.count + 's后重新发送'
					} else {
						// 倒计时完，可点击
						vm.isDisabled = false
						// 更新按钮文字内容
						vm.content = '发送验证码'
						// 清空定时器!!!
						clearInterval(vm.timer)
						vm.timer = null
					}
				}, 1000)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container{
		background-color: #fff;
	}
	.updatepassword {
		min-height: 100vh;
		padding: 0 0;
		padding-top: 85px;
		position: relative;

		.title {
			padding: 0 15px;
			box-sizing: border-box;
			margin-bottom: 20px;
		}

		.bottom-btn {
			position: absolute;
			bottom: 20px;
			font-size: 14px;
			left: 0;
			right: 0;
			padding: 15px 0;
			margin: 0 15px;
			border-radius: 5px;
			text-align: center;
			color: #fff;
			background-color: #00337D;
		}

		.list {
			border: 1px solid #b3b3b3;
			margin: 0 15px;
			padding: 0 10px;
			margin-bottom: 20px;
			box-sizing: border-box;
			border-radius: 5px;
			overflow: hidden;

			input {
				border: 0;
				outline: none;
				width: 100%;
				height: 40px;
			}
			.code-input{
				width: 60%;
			}
			.round {
				display: inline-block;
				color: #0064C8;
				width: 38%;
				text-align: right;
				font-size: 14px;
				cursor: pointer;
			}
			
			.wait {
				cursor: not-allowed;
				color: #E0E0E0;
			}
		}
	}
</style>
